<template>
  <div class="d-flex ml-auto">
    <template v-if="!isLoading">
      <div v-if="aufschiebeDatum">
        {{ isDisplayDateOnly ? '' : 'Aufgeschoben bis:' }} {{ aufschiebeDatum | date }}
        <b-button
          v-b-modal.reisetermin-date-shift-modal
          :title="$t('COMMON.AENDERN')"
          :aria-label="$t('COMMON.AENDERN')"
          variant="secondary"
          class="btn btn-light-primary btn-icon btn-xs d-inline"
        >
          <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
      </div>

      <b-button v-else-if="!isDisplayDateOnly" v-b-modal.reisetermin-date-shift-modal size="sm">
        {{ $t('COMMON.VERSCHIEBEN') }}
      </b-button>
      <div v-else>-</div>
      <b-modal
        id="reisetermin-date-shift-modal"
        title="Frist verschieben"
        button-size="sm"
        size="sm"
        @ok="onModalOk"
      >
        <p>{{ $t('REISETERMINE.FRISTDESREISETERMINS') }} {{ reiseterminWorkflowFristDate | date }}</p>
        <p>
          {{ $t('REISETERMINE.FIRSTDARFNICHTVERSCHOBENWERDENZUM', [maxDateShiftDateString]) }}
        </p>
        <date-picker
          class="w-100"
          v-model="selected"
          :default-value="maxDateShiftDate"
          type="date"
          :lang="langConfig"
          format="DD.MM.YYYY"
          input-class="form-control w-100"
          :placeholder="$t('REISETERMINE.VESCHIEBENBIS')"
          :disabled-date="disabledDate"
        ></date-picker>
      </b-modal>
    </template>
    <b-skeleton v-else class="d-inline" width="100px" height="30px"> </b-skeleton>
  </div>
</template>

<script>
import apiService from '@/core/common/services/api.service';
import { SET_REISETERMIN_RELOAD_NEEDED } from '@/core/produkte/reisetermine/stores/workflow-manager-reisetermin.module.js';

import DatePicker from 'vue2-datepicker';
const moment = require('moment');

export default {
  components: { DatePicker },
  props: {
    isDisplayDateOnly: { type: Boolean, default: false },
    reiseterminWorkflowFristDate: { type: String },
    aufschiebeDatum: { type: String },
    startdatum: { type: String },
    reisekuerzel: { type: String },
  },
  data() {
    return {
      selected: null,
      isLoading: true,
      maxDateShiftDate: null,
      langConfig: {
        formatLocale: {
          months: moment.localeData().months(),
          monthsShort: moment.localeData().monthsShort(),
          weekdays: moment.localeData().weekdays(),
          weekdaysShort: moment.localeData().weekdaysShort(),
          weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          firstDayOfWeek: 1,
        },
      },
      calendarSelectedYear: new Date().getFullYear(),
    };
  },
  async mounted() {
    this.maxDateShiftDate = await this.getMaxSchiebeDatum();
  },
  computed: {
    maxDateShiftDateString() {
      return this.$options.filters.dateAll(this.maxDateShiftDate);
    },
  },
  methods: {
    onModalOk() {
      if (this.selected) {
        apiService
          .put(`Reise/${this.reisekuerzel}/Reisetermin/${this.startdatum}/AufschiebeDatum`, {
            reisekuerzel: this.reisekuerzel,
            startdatum: this.startdatum,
            aufschiebeDatum: this.selected,
          })
          .then(() => {
            this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
            this.$emit('update:aufschiebeDatum', this.selected.toString());
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.$store.commit(SET_REISETERMIN_RELOAD_NEEDED, true);
          });
      }
    },
    async getMaxSchiebeDatum() {
      this.isLoading = true;
      return apiService
        .get(`Reise/${this.reisekuerzel}/Reisetermin/${this.startdatum}/AufschiebeDatum/Max`)
        .then(response => {
          return response.data.result;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    disabledDate(date, currentValue) {
      const currentDate = moment(date);
      return currentDate.isAfter(this.maxDateShiftDate) || currentDate.isBefore(moment());
    },
  },
};
</script>
