<template>
  <div
    v-b-tooltip.hover
    :title="releaseprocessprogress.hasBuchungen ? '' : 'Keine Buchungen auf diesem Reisetermin gefunden.'"
  >
    <i
      class="fas fa-user-check mr-1"
      v-b-tooltip.hover
      :title="releaseprocessprogress.hasBuchungen ? userIconTooltipText : ''"
      :class="userIconClass"
    ></i>
    <i
      class="fas fa-suitcase mr-1"
      v-b-tooltip.hover
      :title="releaseprocessprogress.hasBuchungen ? suitcaseIconTooltipText : ''"
      :class="suitcaseIconClass"
    ></i>
    <i
      class="fas fa-plane mr-1"
      v-b-tooltip.hover
      :title="releaseprocessprogress.hasBuchungen ? fluegeTooltipText : ''"
      :class="fluegeIconClass"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    releaseprocessprogress: { type: Object },
  },
  computed: {
    fluegeIconClass() {
      return this.releaseprocessprogress.hasBuchungen
        ? this.releaseprocessprogress.fluegePassend && this.releaseprocessprogress.flugticketsAusgestellt
          ? 'text-success'
          : this.releaseprocessprogress.fluegePassend || this.releaseprocessprogress.flugticketsAusgestellt
          ? 'text-warning'
          : 'text-danger'
        : 'text-gray';
    },
    suitcaseIconClass() {
      return this.releaseprocessprogress.hasBuchungen
        ? !this.releaseprocessprogress.reiseleistungListenversandPruefstatus ||
          this.releaseprocessprogress.reiseleistungListenversandPruefstatus === 'NichtGeprueft'
          ? 'text-danger'
          : this.releaseprocessprogress.reiseleistungListenversandPruefstatus ===
            'GeprueftNichtKorrektInArbeit'
          ? 'text-warning'
          : 'text-success'
        : 'text-gray';
    },
    userIconClass() {
      return this.releaseprocessprogress.hasBuchungen
        ? !this.releaseprocessprogress.reiseleistungListenversandPruefstatus ||
          this.releaseprocessprogress.teilnehmerPruefstatus === 'NichtGeprueft'
          ? 'text-danger'
          : this.releaseprocessprogress.teilnehmerPruefstatus === 'InArbeit'
          ? 'text-warning'
          : this.releaseprocessprogress.teilnehmerPruefstatus === 'GeprueftVorhandenKorrekt'
          ? 'text-success'
          : 'text-warning'
        : 'text-gray';
    },
  },
  methods: {
    suitcaseIconTooltipText() {
      return this.suitcaseIconClass === 'text-success'
        ? 'Auf allen Vorgängen sind die Reiseleistungen & Listenversand geprüft'
        : this.suitcaseIconClass === 'text-danger'
        ? 'Auf mindestens einem Vorgang wurden die Reiseleistungen & der Listenversand nicht geprüft'
        : 'Auf mindestens einem Vorgang wurden ist die Prüfung Reiseleistungen & Listenversand in Arbeit';
    },
    userIconTooltipText() {
      return this.userIconClass === 'text-success'
        ? 'Auf allen Vorgängen sind die Teilnehmerdaten geprüft'
        : this.userIconClass === 'text-danger'
        ? 'Auf mindestens einem Vorgang wurden die Teilnehmerdaten nicht geprüft'
        : 'Auf mindestens einem Vorgang ist die Prüfung Teilnehmerdaten in Arbeit';
    },
    fluegeTooltipText() {
      return this.fluegeIconClass === 'text-success'
        ? 'Auf allen Vorgängen sind die Flüge geprüft & Flugticket ausgestellt'
        : this.fluegeIconClass === 'text-danger'
        ? 'Auf mindestens einem Vorgang wurden die Flüge nicht geprüft & kein Flugticket ausgestellt'
        : 'Auf mindestens einem Vorgang sind die Flüge in Arbeit oder Flugticket ausgestellt';
    },
  },
};
</script>
