<template>
  <div class="row">
    <div class="col-12 d-flex align-items-center">
      <LoadSpinnerWidget
        v-if="isLoading"
        :dataAvailable="metadata === null"
        :isLoading="isLoading"
        :showText="false"
      ></LoadSpinnerWidget>
      <template v-else>
        <div class="d-flex align-items-center mb-9 mr-2 bg-light-primary rounded p-5">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <div class="font-weight-bold text-dark-75font-size-lg mb-1">Releaseprozess Status</div>
            <span class="font-weight-bold"
              ><TeamdashboardReleaseprocessProgressBar :releaseprocessprogress="metadata" />
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-9 mr-2 bg-light-primary rounded p-5">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <div class="font-weight-bold text-dark-75font-size-lg mb-1">Buchungen released</div>
            <span class="font-weight-bold">{{ metadata.buchungenAmount }} </span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-9 bg-light-primary rounded p-5">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <div class="font-weight-bold text-dark-75font-size-lg mb-1">Buchungen gesamt</div>
            <span class="font-weight-bold">{{ metadata.buchungenTotal }} </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getDomTeamDashboardMetadata } from '@/core/common/services/odata.service';
import TeamdashboardReleaseprocessProgressBar from '@/components/produkte/reisetermine//teamdashboard-releaseprocess-progress-bar.vue';
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

export default {
  components: {
    TeamdashboardReleaseprocessProgressBar,
    LoadSpinnerWidget,
  },
  props: {
    reisetermin: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: true,
      metadata: null,
    };
  },
  async mounted() {
    const reiseterminId = this.reisetermin.id;
    const metadataResponse = await getDomTeamDashboardMetadata([this.reisetermin]);
    try {
      this.metadata = {
        hasBuchungen: metadataResponse[reiseterminId]?.buchungenAmount?.ofTotal > 0, // Buchungen
        fluegePassend: metadataResponse[reiseterminId]?.fluegePassend, // Fluege
        flugticketsAusgestellt: metadataResponse[reiseterminId]?.flugticketsAusgestellt, // Fluege
        reiseleistungListenversandPruefstatus:
          metadataResponse[reiseterminId]?.reiseleistungListenversandPruefstatus, // Suitcase
        teilnehmerPruefstatus: metadataResponse[reiseterminId]?.teilnehmerPruefstatus, // User Icon
        buchungenAmount: metadataResponse[reiseterminId]?.buchungenAmount.amount,
        buchungenTotal: metadataResponse[reiseterminId]?.buchungenAmount.ofTotal, // Buchungen
      };
      this.isLoading = false;
    } catch (e) {
      console.error(e);
      this.metadata = 'Error';
      this.isLoading = false;
    }
  },
};
</script>
