<template>
  <div
    :is="activeProcess"
    :reisetermin="reisetermin"
    :id="reisetermin.id"
    @updated-process="$emit('updated-process', reisetermin.id)"
    class="mt-5"
  ></div>
</template>

<script>
import PruefungDerDurchfuehrungProcessComponent from '@/components/produkte/reisetermine/process-pruefung-der-durchfuehrung.vue';
import PruefungDerDurchfuehrungProcessTodoComponent from '@/components/produkte/reisetermine/process-pruefung-der-durchfuehrung-todo.vue';
import ProcessReleasePruefung from '@/components/produkte/reisetermine/process-release-pruefung.vue';
export default {
  components: {
    PruefungDerDurchfuehrungProcessComponent,
    PruefungDerDurchfuehrungProcessTodoComponent,
    ProcessReleasePruefung,
  },
  props: {
    reisetermin: { type: Object },
    stateName: { type: String },
  },
  data() {
    return {
      isWorkflowComponentLoading: false,
    };
  },
  computed: {
    activeProcess() {
      const processMapped = {
        DurchfuehrbarkeitPruefenDoing: 'PruefungDerDurchfuehrungProcessComponent',
        DurchfuehrbarkeitPruefenTodo: 'PruefungDerDurchfuehrungProcessTodoComponent',
        ReleasepruefungDoing: 'ProcessReleasePruefung',
      };
      return processMapped[this.stateName];
    },
  },
};
</script>
