<template>
  <b-modal
    size="lg"
    ignore-enforce-focus-selector="#advwebForm"
    :visible="modalVisible"
    :title="modalTitle"
    @hide="onModalHide"
    :id="`workflow-modal`"
  >
    <template v-if="reisetermin && !isLoading">
      <!-- Hinweis, falls Reisetermin nicht garantiert -->

      <div
        v-if="!reisetermin?.garantiert"
        class="bg-light-warning w-100 p-5 rounded mb-2 d-flex align-items-center"
      >
        <b-icon icon="list-check" variant="warning" font-scale="2"></b-icon>
        <div class="mr-2 ml-5">
          <span class="text-uppercase">Achtung:</span> in <b>Blank</b> ist der Termin <b>nicht garantiert</b>.
          <br />
          Bitte prüfe, ob der Termin in hogrup auf F gesetzt werden muss.
        </div>
      </div>

      <WorkflowManagerReiseterminInfoPanel v-if="reisetermin" :reisetermin="reisetermin">
      </WorkflowManagerReiseterminInfoPanel>
      <WorkflowManagerReiseterminModalMarkedComments
        v-if="markedComments.length > 0"
        v-allowedRoles="[Role.ADMIN, Role.DOM]"
        :markedComments="markedComments"
      >
      </WorkflowManagerReiseterminModalMarkedComments>
      <div v-if="stateMsg && !isLoading" class="d-flex align-items-center bg-light-warning rounded p-5">
        <b-icon icon="list-check" variant="warning" font-scale="2"></b-icon>
        <div class="d-flex flex-column flex-grow-1 mr-2 ml-5">
          <span class="text-dark-75 font-size-md" v-html="stateMsg"></span>
        </div>
      </div>
      <WorkflowProcessReiseterminComponentLoader
        v-if="reisetermin"
        :reisetermin="reisetermin"
        :stateName="customState.state"
        @updated-process="onProcessUpdated"
      ></WorkflowProcessReiseterminComponentLoader>
    </template>
    <template v-else>
      <b-skeleton class="mb-5" width="100%" height="130px"></b-skeleton>
      <b-skeleton class="mb-5" width="100%" height="80px"></b-skeleton>

      <b-skeleton animation width="85%"></b-skeleton>
      <b-skeleton animation width="55%"></b-skeleton>
      <b-skeleton animation width="70%"></b-skeleton>
    </template>

    <template #modal-footer>
      <template v-if="reisetermin && !isLoading">
        <template v-if="!isOnlySystemTrigger">
          <b-button
            :hidden="item.system || isLoading"
            :disabled="!item.allowed"
            v-for="(item, index) in trigger"
            :key="index"
            @click="onTriggerButtonClick(item.trigger)"
            :variant="(configTrigger[item.trigger] && configTrigger[item.trigger].color) || 'secondary'"
          >
            {{ item.LABEL }}
          </b-button>
        </template>
        <b-button v-else @click="$bvModal.hide(`workflow-modal`)">{{ $t('COMMON.CLOSE') }}</b-button>
      </template>
      <template v-else>
        <b-skeleton type="button" width="100px" height="35px"></b-skeleton>
        <b-skeleton type="button" width="150px" height="35px"></b-skeleton>
      </template>
    </template>
  </b-modal>
</template>

<script>
import triggerConfig from '@/core/common/trigger.config.js';

import WorkflowManagerReiseterminInfoPanel from '@/components/produkte/reisetermine/workflow-manager-reisetermin-info-panel.vue';
import WorkflowProcessReiseterminComponentLoader from '@/components/produkte/reisetermine/workflow-manager-reisetermin-process-component-loader.vue';
import WorkflowManagerReiseterminModalMarkedComments from '@/components/produkte/reisetermine/workflow-manager-reisetermin-modal-marked-comments.vue';

export default {
  components: {
    WorkflowProcessReiseterminComponentLoader,
    WorkflowManagerReiseterminInfoPanel,
    WorkflowManagerReiseterminModalMarkedComments,
  },
  props: {
    isLoading: { type: Boolean },
    customState: { type: Object },
    modalVisible: { type: Boolean },
    modalTitle: { type: String },
    stateMsg: { type: String },
    trigger: { type: Array },
    reisetermin: { type: Object },
  },
  methods: {
    onProcessUpdated(id) {
      this.$emit('updated-process', id);
    },
    onTriggerButtonClick(t) {
      this.$emit('trigger', t);
    },
    onModalHide() {
      this.$emit('workflow-modal-hide');
    },
  },
  computed: {
    configTrigger() {
      return triggerConfig.reisetermine;
    },
    markedComments() {
      return this.reisetermin?.comments?.filter(comment => comment.isMarked) || [];
    },
    isOnlySystemTrigger() {
      // nur schließen button weil nix zu klicken
      return this.trigger !== null ? this.trigger.every(t => t.system === true) : false;
    },
  },
};
</script>
