var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-items-center"},[_c('b-form-group',{staticClass:"flex-fill mb-0"},[(!_vm.isLoading)?_c('div',{staticClass:"row d-flex flex-column col-md-12"},[_c('div',{staticClass:"d-flex"},[_vm._v(" Mindestteilnehmerzahl ist "+_vm._s(_vm.isMinTeilnehmerReached ? '' : 'nicht')+" erreicht. "),_c('b-icon',{class:{
              'text-success': _vm.isMinTeilnehmerReached,
              'text-danger': !_vm.isMinTeilnehmerReached,
              'ml-2': true,
            },attrs:{"font-scale":"1.4","icon":_vm.isMinTeilnehmerReached ? 'check2-circle' : 'x-circle'}})],1),_c('div',{staticClass:"d-flex"},[_vm._v(" Flugtickets sind "+_vm._s(_vm.isFlugticketAllAusgestellt ? '' : 'nicht')+" ausgestellt. "),_c('b-icon',{staticClass:"ml-1",class:{
              'text-success': _vm.isFlugticketAllAusgestellt,
              'text-danger': !_vm.isFlugticketAllAusgestellt,
            },attrs:{"font-scale":"1.4","icon":_vm.isFlugticketAllAusgestellt ? 'check2-circle' : 'x-circle'}})],1),(_vm.reisetermin.durchfuehrungUnterMtz)?_c('div',{staticClass:"d-flex"},[_vm._v(" Sollte die MTZ nicht erreicht sein, muss bei diesem Termin die Durchführung unter MTZ geprüft werden! "),_c('b-icon',{staticClass:"ml-1",class:{
              'text-success': _vm.reisetermin.durchfuehrungUnterMtz,
              'text-danger': !_vm.reisetermin.durchfuehrungUnterMtz,
            },attrs:{"font-scale":"1.4","icon":_vm.reisetermin.durchfuehrungUnterMtz ? 'check2-circle' : 'x-circle'}})],1):_vm._e()]):_c('b-skeleton',{attrs:{"width":"300px","height":"20px"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }