<template>
  <span class="mr-1">
    <b-badge :variant="variant" v-b-popover.hover.top="description" style="cursor: help;">{{ days }}</b-badge>
  </span>
</template>

<script>
export default {
  name: 'workday-ampel-widget',
  props: {
    days: { type: Number },
    description: { type: String },
    displayNegativeDays: { type: Boolean, default: false },
  },
  data() {
    return {
      prueffrist: {
        rot: { min: 2, class: 'danger' },
        gelb: { min: 3, max: 7, class: 'warning' },
        gruen: { min: 8, class: 'success' },
        default: { class: 'success' },
      },
    };
  },
  computed: {
    variant() {
      return this.days <= this.prueffrist.rot.min
        ? this.prueffrist.rot.class
        : this.days >= this.prueffrist.gelb.min && this.days <= this.prueffrist.gelb.max
        ? this.prueffrist.gelb.class
        : this.days >= this.prueffrist.gruen.min
        ? this.prueffrist.gruen.class
        : this.prueffrist.default.class;
    },
  },
};
</script>
