<template>
  <div>
    <b-button variant="outline-primary" class="mb-2 w-100" v-b-toggle.collapse-flugek
      >{{ $t('COMMON.MARKEDCOMMENTS') }}
      <b-icon :icon="isVisible ? 'chevron-compact-up' : 'chevron-compact-down'" aria-hidden="true"></b-icon
    ></b-button>
    <b-collapse v-model="isVisible" id="collapse-flugek">
      <ReiseterminComment
        v-for="comment in markedComments"
        :key="comment.id"
        :comment="comment"
        :isToolbarHidden="true"
        :isInWorkflowModal="true"
        :isAnswer="true"
        :isAdmin="isAdmin"
        :isLoading="isLoading"
        :ownUserId="ownUserId"
      ></ReiseterminComment>
    </b-collapse>
  </div>
</template>

<script>
import ReiseterminComment from '@/components/produkte/reisetermine/reisetermin-comment.vue';
import { mapState } from 'vuex';

export default {
  name: 'WorkflowManagerReiseterminModalMarkedComments',
  components: {
    ReiseterminComment,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    markedComments: { type: Array },
  },
  computed: {
    ...mapState({
      ownUserId: state => state.auth.account.localAccountId,
      isLoading: state => state.auth.account.isLoading,
      isAdmin: state => state.auth.account.idTokenClaims.roles.includes('Admin'),
    }),
  },
};
</script>
