<template>
  <div class="row">
    <div class="col-12 d-flex align-items-center">
      <b-form-group class="flex-fill mb-0">
        <div v-if="!isLoading" class="row col-md-12">
          Flugtickets sind {{ isFlugticketAllAusgestellt ? '' : 'nicht' }} ausgestellt.
          <b-icon
            font-scale="1.4"
            class="ml-1"
            :class="{
              'text-success': isFlugticketAllAusgestellt,
              'text-danger': !isFlugticketAllAusgestellt,
            }"
            :icon="isFlugticketAllAusgestellt ? 'check2-circle' : 'x-circle'"
          >
          </b-icon>
        </div>
        <b-skeleton v-else width="300px" height="20px"></b-skeleton>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import * as odataService from '@/core/common/services/odata.service';

export default {
  name: 'PruefungDerDurchfuehrungProcessTodoComponent',
  props: {
    reisetermin: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: false,
      isFlugticketAllAusgestellt: false,
    };
  },
  async mounted() {
    this.isFlugticketAllAusgestellt = await this.getVorgaengeWithFlugticketNotAusgestellt(
      this.reisetermin.id
    );
  },
  methods: {
    getVorgaengeWithFlugticketNotAusgestellt(id) {
      const filter = {
        not: {
          ['blankState']: {
            in: [
              'StornierteBuchung',
              'StornierteOption',
              'StornierteAnfrage',
              'StornierteWarteliste',
              'StornierteVormerkung',
              'StorniertOhneRechnung',
            ],
          },
        },
        flugticketAusgestellt: false,

        hotelleistungen: {
          any: {
            isDeleted: false,
            reiseterminId: parseInt(id),
          },
        },
      };
      return odataService
        .getVorgang({
          expand: {
            hotelleistungen: {
              expand: {
                Reisetermin: {
                  filter: {
                    isDeleted: false,
                  },
                  expand: {
                    gueltigeKonfiguration: {},
                    Reise: { expand: { konfigurationen: {} } },
                  },
                },
              },
            },
          },
          filter,
        })
        .then(response => {
          return response.data && response.data.length === 0;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
