<template>
  <div>
    <WorkflowManagerModalReisetermin
      :isLoading="isLoading"
      :customState="customState"
      :modalVisible="isVisible"
      :modalTitle="title"
      :stateMsg="stateMsg"
      :trigger="trigger"
      :reisetermin="reisetermin"
      @workflow-modal-hide="setVisible(false)"
      @updated-process="onProcessUpdated"
      @trigger="saveTrigger"
    >
    </WorkflowManagerModalReisetermin>
  </div>
</template>

<script>
import WorkflowManagerModalReisetermin from '@/components/produkte/reisetermine/workflow-manager-reisetermin-modal.vue';
import { mapActions, mapState, mapMutations } from 'vuex';

import {
  SEND_TRIGGER,
  SET_TRIGGER_BY_ID,
  SET_VISIBLE,
  SET_LOADING,
  SET_STATE_BY_ID,
  SET_CONFIRM_MSG_BOX_OK,
} from '@/core/produkte/reisetermine/stores/workflow-manager-reisetermin.module.js';

export default {
  components: {
    WorkflowManagerModalReisetermin,
  },
  watch: {
    userConfirmationNeeded(userMustConfirm) {
      if (userMustConfirm) {
        this.$bvModal.msgBoxConfirm(this.confirmMsg.text, this.confirmMsg.options).then(ok => {
          this.setConfirmMsgBoxOK(ok);
        });
      }
    },
    notifyMsg() {
      this.$bvModal.msgBoxOk(this.notifyMsg.text, this.notifyMsg.options);
    },
    reisetermin() {
      this.setStateById({ id: this.reisetermin.id });
    },
  },
  computed: {
    ...mapState({
      title: state => state.workflowManagerReisetermin.title,
      stateMsg: state => state.workflowManagerReisetermin.stateMsg,
      reisetermin: state => state.workflowManagerReisetermin.reisetermin,
      notifyMsg: state => state.workflowManagerReisetermin.notifyMsg,
      confirmMsg: state => state.workflowManagerReisetermin.confirmMsg,
      userConfirmationNeeded: state => state.workflowManagerReisetermin.userConfirmationNeeded,
      isInitialStateSet: state => state.workflowManagerReisetermin.isInitialStateSet,
      trigger: state => state.workflowManagerReisetermin.trigger,
      customState: state => state.workflowManagerReisetermin.customState,
      isLoading: state => state.workflowManagerReisetermin.isLoading,
      isVisible: state => state.workflowManagerReisetermin.isVisible,
    }),
  },
  methods: {
    ...mapActions({
      setStateById: SET_STATE_BY_ID,
      setTriggerById: SET_TRIGGER_BY_ID,
      sendTrigger: SEND_TRIGGER,
      setConfirmMsgBoxOK: SET_CONFIRM_MSG_BOX_OK,
    }),
    ...mapMutations({ setLoading: SET_LOADING, setVisible: SET_VISIBLE }),
    async onProcessUpdated() {
      //Updates the trigger if the process component changed a value
      //e.g if a attribute is required to be able to click a trigger
      this.setTriggerById(this.reisetermin.id);
    },
    async saveTrigger(triggerCode) {
      //get the confirmMsg from the store
      this.sendTrigger({ id: this.reisetermin.id, triggerCode });
    },
  },
};
</script>
